.ENContainer {
    background-color: #263F6A;
    border-radius: 8px;
    /* justify-items: center; */
    display: flex;
    flex-direction: column;
}

.ENContainerContent {
    max-height: 470px;
    max-width: 320px;
    min-height: 470px;
    min-width: 320px;
}

.ENTopTitleSection {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 8%;
    border-bottom: #E0E0E0 2px solid;
    margin-bottom: 2px;
}

.ENMainTitle {
    margin-bottom: -4px;
    font-size: 20px;
    margin-left: 16px;
    color: white;
}

.ENIcon {
    font-size: 30px;
    margin-bottom: 0px;
    color: white;
}

.ENSectionTitle {
    background-color: #DBDBDB;
    min-height: 8%;
    max-height: 8%;
    font-size: 18px;
    text-align: left;
    display: flex;
    align-items: center;
    padding-left: 18px;
    margin-bottom: 0px;
    padding-top: 2px;
    padding-bottom: 2px;
}

.ENInfoSectionContainer {
    background-color: #f7b5b0;
    margin-bottom: 0px;
    display: flex;
    flex-direction: column;
}

.ENInfoSection {
    width: 90%;
    background-color: white;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    padding-bottom: 8px;
    padding-top: 0px;
    height: 26%;
    align-self: center;
}

.ENTitle {
    font-size: 14px;
    color: #616161;
    margin-top: 4px;
}

.ENTitleText {
    border: black 1px solid;
    border-radius: 4px;
    height: 32px;
    /* text-align: right; */
    display: flex;
    align-items: center;
    justify-content: right;
    padding-right: 10px;
    font-size: 16px;
    background: #ededed;
}

.ENTitleInput {
    align-items: center;
    background: #ffffff;
    border: 1px solid #000;
    border-radius: 4px;
    display: flex;
    font-size: 16px;
    height: 32px;
    justify-content: right;
    padding-right: 10px;
    width: 100%;
    text-align: left;
    padding-right: 30px;
    padding-left: 10px;
}

.ENConversationSectionContainer {
    background-color: #f7b5b0;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
}

.ENConversationSection {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    margin-right: 10px;
    align-self: center;
    padding: 0px 8px 8px 8px;
    width: 90%;
    max-height: 70px;
    min-height: 70px;
}

.ENSelectTitle {
    font-size: 14px;
    color: #000000;
    margin-top: 1px;
    position: relative;
    display: inline-block;

}
.ENSelectTitle select{
    appearance: none;
}

.ENOptionContent {
    display: flex;
    flex-direction: column;
    align-items: left;
    margin-bottom: 2px !important;
}

.ENOptionTopContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0px;
    height: fit-content;
}

.ENOptionMoveUpAndDown {
    height: fit-content;
    display: flex;
    flex-direction: column;
}

.ENOptionMoveUp {
    background-color: white;
    border: none;
    height: fit-content;
    font-size: 18px;
    color: #263F6A;
}

.ENOptionMoveDown {
    background-color: white;
    border: none;
    height: fit-content;
    font-size: 18px;
    color: #263F6A;
    margin-top: -4px;
}

.ENOptionText {
    display: flex;
    font-size: 14px;
    margin-top: 4px;
    /* height: fit-content; */
    max-height: 130px;
    min-height: 130px;
    overflow-wrap: break-word;
    padding-left: 5px;
    resize: none;
    white-space: normal;
    width: 100%;
}

.ENOptionNewFlowContainer {
    display: flex;
    flex-direction: row;
    margin-top: 4px;
    margin-left: 4px;
}

.ENOptionDeleteButton {
    background-color: white;
    border: none;
    font-size: 16px;
}

.ENOptionNewFlowText {
    width: fit-content;
    margin-left: 2px;
    margin-right: 8px;
    font-size: 14px;
}

.ENOptionFlowNameInput {
    background: #fff;
    text-align: left;
    border: 1px solid black;
    border-radius: 4px;
    margin-left: 6px;
    width: 230px;
    height: 36px;
}

.ENOptionNewFlowCheckbox {
    width: 14px;
}

.ENAddOptionButton {
    background-color: white;
    border: none;
}

.ENDeleteAndJSONContainer {
    margin-bottom: 24px;
    background-color: #f7b5b0;
    display: flex;
    justify-content: center;
    height: 10%;
}

.ENDeleteAndJSON {
    width: 90%;
    display: flex;
    justify-content: space-around;
    background-color: white;
    padding-top: 4px;
    padding-bottom: 4px;
}

.ENDeleteButton {
    color: white;
    background-color: #D52B1E;
    border: none;
    border-radius: 4px;
    margin-top: 6px;
    margin: 4px;
    height: 40px;
    width: 180px;
}

.Feedback_Container {
    display: flex;
    align-self: center;
}

.Feedback_Container>input {
    margin-right: 10px;
}

.Feedback_Container>p {
    font-size: 16px;
    margin: 0 !important;
}

.ENChevron{
    position: relative;
    top: -24px;    
    left: 237px;    
    width: 12px;
    height: 12px;
    pointer-events: none;
}

@media only screen and (max-width: 1600px) {
    .ENMainTitle {
        font-size: 18px;
        margin-left: 12px;
    }

    .ENIcon {
        font-size: 26px;
    }

    .ENSectionTitle {
        font-size: 16px;
    }

    .ENTitle {
        font-size: 12px;
    }

    .ENSelectTitle {
        font-size: 12px;
    }

    .ENTitleText {
        font-size: 14px;
        height: 30px;
    }

    .ENTitleInput {
        font-size: 14px;
        height: 30px;
    }

    .ENConversationSection {
        max-height: 64px;
        min-height: 64px;
    }

    .ENOptionText {
        font-size: 12px;
        max-height: 110px;
        min-height: 110px;
    }

    .ENDeleteButton {
        height: 30px;
        width: 160px;
    }

    .ENContainerContent {
        max-height: 420px;
        max-width: 300px;
        min-height: 420px;
        min-width: 300px;
    }

    .ENOptionNewFlowText {
        font-size: 14px;
    }

    .ENOptionFlowNameInput {
        background: #fff;
        text-align: left;
        border: 1px solid black;
        border-radius: 4px;
        margin-left: 6px;
        width: 210px;
        height: 32px;
        font-size: 14px;
    }

    .ENOptionNewFlowCheckbox {
        width: 14px;
    }

    .Feedback_Container>p {
        font-size: 14px;
        margin: 0 !important;
    }

    .Chevron {
        top: -23px;    
        left: 216px;  
    }
}

@media only screen and (max-width: 1300px) {
    .ENMainTitle {
        font-size: 16px;
        margin-left: 10px;
    }

    .ENIcon {
        font-size: 24px;
    }

    .ENSectionTitle {
        font-size: 14px;
    }

    .ENTitle {
        font-size: 12px;
        margin-top: 2px;
    }

    .ENSelectTitle {
        font-size: 12px;
        margin-top: 2px;
    }

    .ENTitleText {
        font-size: 12px;
        height: 26px;
    }

    .ENTitleInput {
        font-size: 12px;
        height: 28px;
    }

    .ENConversationSection {
        max-height: 54px;
        min-height: 54px;
    }

    .ENOptionText {
        font-size: 12px;
        max-height: 96px;
        min-height: 96px;
    }

    .ENDeleteButton {
        height: 28px;
        width: 160px;
        font-size: 15px;
    }

    .ENContainerContent {
        max-height: 374px;
        max-width: 280px;
        min-height: 374px;
        min-width: 280px;
    }

    .ENAddOptionButton {
        font-size: 15px;
    }

    .ENOptionMoveUp {
        font-size: 16px;
    }

    .ENOptionMoveDown {
        font-size: 16px;
    }

    .ENOptionDeleteButton {
        font-size: 14px;
    }

    .ENOptionNewFlowText {
        font-size: 13px;
    }

    .ENOptionFlowNameInput {
        background: #fff;
        text-align: left;
        border: 1px solid black;
        border-radius: 4px;
        margin-left: 6px;
        width: 200px;
        height: 30px;
        font-size: 13px;
    }

    .ENOptionNewFlowCheckbox {
        width: 12px;
    }

    .Feedback_Container>p {
        font-size: 13px;
        margin: 0 !important;
    }

    .ENChevron {
        top: -24px;    
        left: 218px;  
    }
}